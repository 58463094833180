<template>
  <drawer-form
    ref="drawerForm"
    :title="model.productKey?'编辑':'新增'"
    :model="model"
    :rules="rules"
    :loading="loading"
    @handleSubmit="handleSubmit">


    <a-form-model-item label='产品' prop="name">
      <a-input v-model="model.name" disabled/>
    </a-form-model-item>

    <a-form-model-item label='代理提成' prop="commissionRate">
      <a-input v-model="model.commissionRate"/>
    </a-form-model-item>

    <a-form-model-item label='一级提成' prop="commissionRate1">
      <a-input v-model="model.commissionRate1"/>
    </a-form-model-item>

    <a-form-model-item label='二级提成' prop="commissionRate2">
      <a-input v-model="model.commissionRate2"/>
    </a-form-model-item>


  </drawer-form>

</template>
<script>

  export default {
    data() {
      return {
        model: {},

        rules: {
          name: [
            {required: true, message: '不能为空'},
          ],
          commissionRate: [
            {required: true, message: '不能为空'},
          ],
          commissionRate1: [
            {required: true, message: '不能为空'},
          ],
          commissionRate2: [
            {required: true, message: '不能为空'},
          ],
        },
        loading: false,

      }
    },

    methods: {
      show(model={}) {
        this.model = JSON.parse(JSON.stringify(model))
        this.loading=false

        this.$refs.drawerForm.show()

      },

      hide() {
        this.$refs.drawerForm.hide()
      },

      handleSubmit() {
        if (this.model.productKey) {
          this.edit()
        } else {
          this.add()
        }
      },
      add() {
        // this.loading = true
        // this.$post('sharingPack', this.model).then((r) => {
        //   this.loading = false
        //   this.$emit('success')
        //   this.hide()
        // }).catch(() => {
        //   this.loading = false
        // })
      },

      edit() {
        this.loading = true
        this.$put('product', this.model).then((r) => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(() => {
          this.loading = false
        })
      },

    }
  }
</script>
